<template>
  <el-dialog v-if="isArrange" :title="title" :visible.sync="isArrange" width="560px" :before-close="closeArrange">
    <el-form style="width:100%" :rules="rules" ref="redform" :model="redform" label-width="100px">
      <el-form-item label="测量时间：" prop="collectDate">
        <el-date-picker style="width:100%" type="datetime" v-model="redform.collectDate" format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择时间" />
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="isArrange = false">取 消</el-button>
      <el-button size="small" type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="submitForm">确 定
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { insertNucleateTask, insertAntibodyTask } from '../../../api/QuarantineRegistration/quarantineRegistrationpop';

export default {
  name: "AddRecordDialog",
  components: {},
  data() {
    return {
      isArrange: false,
      fullscreenLoading: false,
      title: "",
      redform: {
        collectDate: "",
      },
      rules: {
        collectDate: [{ required: true, message: "请选择时间", trigger: "blur" }]
      },
      regId: null,
      detects: [],
      antibodyDetects: [],
      maxTime: ""  // 数组中最大时间
    }
  },

  methods: {
    show(id, type) {
      this.isArrange = true;
      this.regId = id;
      this.title = type === "核酸检测" ? "新增核酸监测" : "新增抗体监测";
      this.redform = {
        collectDate: ''
      }

      this.detects = this.$parent.detects;
      this.antibodyDetects = this.$parent.antibodyDetects;

      if (this.title === '新增核酸监测') {
        this.detects.shellSort({ key: "date", desc: true })
        // this.maxTime = this.detects[0].date;
        this.maxTime = this.antibodyDetects>0?this.antibodyDetects[0].date:"";
      } else {
        this.antibodyDetects.shellSort({ key: 'date', desc: true })
        // this.maxTime = this.antibodyDetects[0].date;
        this.maxTime = this.antibodyDetects>0?this.antibodyDetects[0].date:"";
      }
    },

    closeArrange() {
      this.isArrange = false;
    },

    submitForm() {
      this.$refs["redform"].validate(async valid => {
        if (valid) {
          let { collectDate } = this.redform;

          // 如果测量时间小于采样时间 就提示
          if (collectDate < this.maxTime) {
            this.$message.warning("测量时间不能小于最近一次采样时间")
            return;
          }

          let params = {
            collectDate,
            regId: this.regId
          };

          this.fullscreenLoading = true;
          let result;
          if (this.title === '新增核酸监测') {
            result = await insertNucleateTask(params);
          } else {
            result = await insertAntibodyTask(params);
          }
          let { code, msg } = result.data;
          if (code == 200) {
            this.$message.success(msg);
            this.isArrange = false;
            this.$parent.getIsolateDetailByRegId(this.regId);
          } else {
            this.$message.error(msg);
          }
          this.fullscreenLoading = false;
        }
      });
    }
  }
}
</script>

<style scoped>
.el-dialog__wrapper>>>.el-dialog__body {
  padding: 14px 20px;
}

.el-dialog__wrapper>>>.el-dialog__body .el-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.el-dialog__wrapper>>>.el-dialog__body .el-form .el-form-item {
  margin: 8px 0 !important;
}
</style>
